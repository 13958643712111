<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2023-07-18 10:03:57
 * @LastEditors: kecraft
 * @LastEditTime: 2024-02-29 13:46:50
 * @FilePath: /impact-iotos-console/src/App.vue
-->
<template>
  <div>
    <el-config-provider :locale="zhCn">
      <router-view />
    </el-config-provider>
  </div>
</template>

<script setup>
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
</script>