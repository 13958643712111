<template>
  <div class="home-page">
    <div class="header-container">
      <div class="header-left">
        <img src="./assets/logo1.png">
        物联⽹平台
      </div>
      <div class="header-right">
        <img src="@/assets/icon/file-icon.png" class="file-link" @click="gotoFile">
        <div class="btn-bell">
          <!-- <el-tooltip effect="light">
            <template #content>
              <div class="nomsg">暂无数据</div>
            </template> -->
          <router-link to="#">
            <img src="@/assets/icon/bell.png" class="file-link">
          </router-link>
          <!-- </el-tooltip> -->
          <span class="btn-bell-badge" v-if="count !== 0">{{ count }}</span>
        </div>
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <div class="user-avator">
            <img :src="require(`@/assets/userLogo.png`)" />
            <span class="el-dropdown-link">
              {{ username }}
            </span>
          </div>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="bottom-container">
      <div class="menu-container">
        <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
          <NavBar :menuList="menuList" />
        </el-scrollbar>
      </div>
      <div class="maincontent">
        <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
          <router-view :key="route.fullPath" />
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Bell } from "@element-plus/icons-vue";
import { onMounted, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import NavBar from "@/components/menu/navbar.vue";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
const menuList = ref([]);
const count = ref(0)
const username = ref("普通用户")
onMounted(() => {
  console.log(process.env)
  menuList.value = [
    {
      icon: "dashboard",
      id: 0,
      link: "/dashboard",
      name: "首页",
      children: []
    },
    {
      icon: "product",
      id: 1,
      link: "/product",
      name: "产品中心",
      children: [
        {
          icon: "product-list",
          id: 11,
          link: "/product/list",
          name: "产品管理",
        },
        {
          icon: "device-list",
          id: 12,
          link: "/device/list",
          name: "设备管理",
        },
        {
          icon: "category",
          id: 13,
          link: "/category",
          name: "品类管理",
          children: []
        },
        {
          icon: "upgrade",
          id: 23,
          link: "/upgrade",
          name: "远程升级",
        },
      ],
    },
    {
      icon: "drive",
      id: 2,
      link: "/drive",
      name: "驱动管理",
      children: [
        {
          icon: "drive-list",
          id: 21,
          link: "/list",
          name: "驱动列表",
        },
        {
          icon: "task-list",
          id: 22,
          link: "/task/list",
          name: "任务列表",
        },
      ]
    },
    {
      icon: "rules",
      id: 3,
      link: "/rules",
      name: "数据订阅",
      children: [
        {
          icon: "rules-list",
          id: 31,
          link: "/list",
          name: "规则引擎",
        },
      ]
    }

  ];
  const token_url = process.env.VUE_APP_TOKEN
  const info = JSON.parse(localStorage.getItem(token_url + "userInfo"));
  if (info && info.userName) {
    username.value = info.userName
  }
})
const isLogout = ref(false);
const handleCommand = (command) => {
  if (command == "logout") {
    //登出 清token  跳转登录页
    ElMessageBox.confirm("确定要退出登录吗？", "提示", {
      type: "warning",
      confirmButtonText: "确定", // 确定按钮的文本内容
      cancelButtonText: "取消", // 取消按钮的文本内容
    })
      .then(() => {
        if (isLogout.value) {
          return;
        }
        isLogout.value = true;
        const token_url = process.env.VUE_APP_TOKEN
        localStorage.removeItem(token_url);
        localStorage.removeItem(token_url + "userInfo");
        isLogout.value = false;
        ElMessage.success("登出成功！")
        router.push("/login");
      })
      .catch(() => { });
  }
}
const gotoFile = () => {
  window.open("http://10.10.49.179:3824/web/#/632016434")
}
</script>

<style lang="less" scoped>
.file-link {
  width: 22px;
  height: 24px;
  margin-left: 30px;
  cursor: pointer;
}

.header-container {
  width: 100%;
  height: 66px;
  background: linear-gradient(180deg, #6289C4 0%, #355BA2 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 30px 0 43px;

  .header-left {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 28px;
      margin-right: 12px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .user-avator {
      margin-left: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-size: 16px;
        color: #fff;
      }
    }

    .user-avator img {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;
    }
  }
}

.bottom-container {
  display: flex;

  .menu-container {
    box-sizing: border-box;
    padding: 19px;
    width: 300px;
    border-right: 1px solid #E9ECF0;
    height: calc(100vh - 66px);
  }

  .maincontent {
    box-sizing: border-box;
    height: calc(100vh - 66px);
    width: calc(100vw - 300px);
  }
}

.btn-bell {
  font-size: 20px;
}

.menu-container :deep(.el-menu) {
  border-right: 0;
}
</style>