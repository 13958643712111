import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

import router from "./router/router"

import store from "./store/index"

import preventReClick from './utils/preventReClick'

import rem from "./utils/rem"


const app = createApp(App)

app.use(router)

app.use(rem)

app.use(store)

app.use(ElementPlus)

app.use(VideoPlayer)

app.use(preventReClick)

app.mount('#app')
