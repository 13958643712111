<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2023-07-24 09:22:14
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-11 09:17:48
 * @FilePath: /impact-iotos-console/src/components/menu/navbar.vue
-->
<template>
  <div class="mymenu">
    <el-menu :default-active="menuIndex1" class="el-menu-vertical-demo" @select="select" text-color="#000"
      active-text-color="#559EFF" :collapse-transition="false" :router="true" :collapse="isCollapse">
      <template v-for="(menu, menuIndex) of menuList">
        <el-sub-menu :key="menuIndex" :index="menuIndex + ''" v-if="menu.children.length > 0">
          <template #title>
            <img :src="require(`@/assets/menu/${menu.icon}-1.png`)" v-if="menu.link === menuIndex1" />
            <img :src="require(`@/assets/menu/${menu.icon}.png`)" v-else-if="menu.icon" />
            {{ menu.name }}
          </template>
          <el-menu-item-group>
            <el-menu-item v-for="(list, index) of menu.children" :key="index" :index="menu.link + list.link">
              <img :src="require(`@/assets/menu/${list.icon}-1.png`)"
                v-if="list.icon && menu.link + list.link === menuIndex1" />
              <img :src="require(`@/assets/menu/${list.icon}.png`)" v-else-if="list.icon" />
              {{ list.name }}
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item :key="menuIndex + 100" :index="menu.link" v-else>
          <img :src="require(`@/assets/menu/${menu.icon}-1.png`)" v-if="menu.icon && menu.link === menuIndex1" />
          <img :src="require(`@/assets/menu/${menu.icon}.png`)" v-if="menu.icon && menu.link !== menuIndex1" />
          {{ menu.name }}
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
export default {
  name: "customMenu",
  props: {
    menuList: {
      type: Array,
      default: null,
    },
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route: {
      handler(val, oldval) {
        if (val.path !== oldval.path) {
          this.menuIndex1 = val.path
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  data() {
    return {
      menuIndex1: this.$route.path
    }
  },
  mounted() {
    this.menuIndex1 = this.$route.path
  },
  methods: {
    select(key) {
      this.menuIndex1 = key
    },
  },
};
</script>


<style lang="less" scoped>
img {
  width: 18px;
  height: 18px;
  margin-right: 13px;
}

:deep(.el-menu-item:hover) {
  background: #EBEFF6;
}

:deep(.el-menu-item.is-active) {
  color: #367CC5;
}
</style>