const state = {
    test: '',
    pk: '',
}
const mutations = {
    CHANGE_TEST_VALUE: (state, view) => {
        state.test = view
    },
    CHANGE_PK_VALUE: (state, view) => {
        state.pk = view
    },
}
const actions = {
    //修改调用 this.$store.dispatch("vuexTest/changeTestValue",v)
    //获取vuex值this.$store.state.vuexTest.test
    changeTestValue({ commit }, view) {
        commit('CHANGE_TEST_VALUE', view)
    },
    changePkValue({ commit }, view) {
        commit('CHANGE_PK_VALUE', view)
    },
}

export default {
    state,
    namespaced: true,
    mutations,
    actions
}
