/*
 * @Description: 
 * @Author: kecraft
 * @Date: 2023-07-24 09:22:14
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-11 14:01:10
 * @FilePath: /impact-iotos-console/src/router/router.js
 */
import { createRouter, createWebHistory } from "vue-router";
// import home from "@/index.vue";
import homePage from "@/indexNew.vue";

const routes = [
    {
        path: "/",
        component: homePage,
        redirect: "/dashboard",
        children: [
            {
                path: '/dashboard',
                name: '看板',
                meta: { title: '看板' },
                component: () => import("@/views/dashboard/index.vue"),
            },
            {
                path: 'product/product',
                name: '产品管理',
                children: [
                    {
                        path: 'list',
                        name: '产品管理',
                        meta: { title: '产品管理' },
                        component: () => import("@/views/product/index.vue"),
                    },
                    {
                        path: 'add',
                        name: '创建产品',
                        meta: { title: '创建产品' },
                        component: () => import("@/views/product/productAdd.vue"),
                    },
                    {
                        path: 'details',
                        name: '产品详情',
                        meta: { title: '产品详情' },
                        component: () => import("@/views/product/details.vue"),
                    },
                ]
            },
            {
                path: '/product/device',
                name: '设备管理',
                children: [
                    {
                        path: 'list',
                        name: '设备管理',
                        meta: { title: '设备管理' },
                        component: () => import("@/views/device/index.vue"),
                    },
                    {
                        path: 'details',
                        name: '设备详情',
                        meta: { title: '设备详情' },
                        component: () => import("@/views/device/device/detail/detail.vue"),
                    },

                ]
            },
            {
                path: '/product/category',
                name: '品类管理',
                meta: { title: '品类管理' },
                component: () => import("@/views/category/category.vue"),
            },
            {
                path: '/rules',
                name: '规则引擎',
                meta: { title: '规则引擎' },
                children: [
                    {
                        path: 'list',
                        name: '规则列表',
                        meta: { title: '规则列表' },
                        component: () => import("@/views/rules/list/index.vue"),
                    },
                    {
                        path: 'history',
                        name: '历史记录',
                        meta: { title: '历史记录' },
                        component: () => import("@/views/rules/history/index.vue"),
                    },
                    {
                        path: 'add',
                        name: '创建规则',
                        meta: { title: '创建规则' },
                        component: () => import("@/views/rules/add/index.vue"),
                    },
                ]
            },
            {
                path: '/drive',
                name: '驱动管理',
                children: [
                    {
                        path: 'list',
                        name: '驱动列表',
                        meta: { title: '驱动列表' },
                        component: () => import("@/views/drive/list/driveManagement.vue"),
                    },
                    {
                        path: 'add',
                        name: '创建驱动',
                        meta: { title: '创建驱动' },
                        component: () => import("@/views/drive/list/driveAdd.vue"),
                    },
                    {
                        path: '/drive/task/list',
                        name: '任务列表',
                        meta: { title: '任务列表' },
                        component: () => import("@/views/drive/task/taskList.vue"),
                    },
                    {
                        path: '/drive/task/details',
                        name: '运行详情',
                        meta: { title: '运行详情' },
                        component: () => import("@/views/drive/task/taskDetails.vue"),
                    },
                    {
                        path: '/product/upgrade',
                        name: '升级管理',
                        meta: { title: '升级管理' },
                        component: () => import("@/views/upgrade/index.vue"),
                    },
                ]
            },

        ]
    },
    // {
    //     path: "/home",
    //     component: homePage,
    // },
    {
        path: '/login',
        component: () => import('@/views/login/login.vue')
    },
    {
        path: '/test',
        component: () => import('@/views/text.vue')
    },
    {
        path: '/agreementDocument',
        component: () => import('@/views/product/components/feature/common/agreementDocument.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

//路由守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/login') return next()
    const token_url = process.env.VUE_APP_TOKEN
    const tokenstr = localStorage.getItem(token_url)
    if (!tokenstr) return next('/login')
    next()
})
export default router;